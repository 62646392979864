import { AccessDenied, useUsers } from "common";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Page } from "app/shared/ui/Page";
import { FacultyLeaveProvider } from "app/services/facultyLeaveContext";
import { FacultyLeaveFilter } from "app/components/FacultyLeave/FacultyLeaveFilters";
import { FacultyLeave } from "app/components/FacultyLeave/FacultyLeave";
import { LeaveAccruedTaken } from "app/components/LeaveAccruedTaken/LeaveAccruedTaken";

export const AppRoutes = () => {
  const { accessDenied } = useUsers();
  const { t } = useTranslation();

  if (accessDenied) {
    return <AccessDenied supportEmail={t("app.supportEmail")} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <FacultyLeaveProvider>
            <Page
              exact={true}
              title="FLA.mainView.title"
              filter={<FacultyLeaveFilter />}
              main={<FacultyLeave />}
            />
          </FacultyLeaveProvider>
        }
      />
      <Route
        path="/faculty"
        element={
          <FacultyLeaveProvider>
            <Page
              exact={true}
              title="FLA.mainView.title"
              filter={<FacultyLeaveFilter />}
              main={<FacultyLeave />}
            />
          </FacultyLeaveProvider>
        }
      />
      <Route
        path="/faculty/:universityId"
        element={
          <FacultyLeaveProvider>
            <Page
              exact={true}
              title={null}
              filter={null}
              main={<LeaveAccruedTaken />}
            />
          </FacultyLeaveProvider>
        }
      />
    </Routes>
  );
};
