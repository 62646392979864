import { Children, cloneElement, useState } from "react";
import {
  Drawer as MuiDrawer,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  Box as MuiBox,
} from "@mui/material";
import {
  ChevronLeft as MuiChevronLeftIcon,
  ChevronRight as MuiChevronRightIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const FilterPanel = ({
  open,
  handleDrawerToggle,
  location,
  changeFilterData,
  history,
  children,
}) => {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({});

  return (
    <MuiBox
      id="filterPanel"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <MuiIconButton
        id="openDrawerButton"
        onClick={handleDrawerToggle}
        edge="start"
        size="small"
        sx={{
          display: open ? "none" : "block",
          marginTop: "24px",
        }}
      >
        <MuiChevronRightIcon fontSize="small" />
      </MuiIconButton>
      <MuiDrawer
        id="filterPanelDrawer"
        transitionDuration={0}
        variant="persistent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "260px" : 0,
          "& .MuiDrawer-paper": {
            width: "260px",
            zIndex: 0,
            position: "relative",
            border: "none",
          },
          "& .MuiTypography-root": { color: "text.primary" },
          "& .MuiGrid-root": { justifyContent: "space-around" },
        }}
      >
        <MuiBox
          id="filterPanelContent"
          sx={{
            padding: "24px 24px 0px 0px",
            display: "grid",
            rowGap: "25px",
            width: "100%",
          }}
        >
          <MuiBox
            id="toolbar"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MuiTypography variant="h6">
              {t("FLA.filterPanel.title")}
            </MuiTypography>
            <MuiIconButton onClick={handleDrawerToggle} sx={{ margin: 0 }}>
              <MuiChevronLeftIcon fontSize="small" />
            </MuiIconButton>
          </MuiBox>
          {Children.map(children, (child) => {
            return cloneElement(child, {
              location,
              history,
              changeFilterData,
              filterData,
              setFilterData,
            });
          })}
        </MuiBox>
      </MuiDrawer>
    </MuiBox>
  );
};
