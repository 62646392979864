import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  Table as MuiTable,
  Paper as MuiPaper,
  Tooltip as MuiTooltip,
  Checkbox as MuiCheckbox,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  TableSortLabel as MuiTableSortLabel,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { useAlerts } from "common";
import { ListAlt as MuiListAltIcon } from "@mui/icons-material";
import { convertDateStrToFLADateStr } from "app/shared/utils";
import { getFacultyLeaveList } from "app/services/facultyLeaveService";
import { useFacultyLeaveContext } from "app/services/facultyLeaveContext";

const EnhancedTableHead = (props) => {
  const [checked, setChecked] = useState(false);
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleCheckAll(event.target.checked);
  };
  return (
    <MuiTableHead
      sx={{
        // keeps the Table Header Label text white
        "& .MuiTableSortLabel-root": {
          color: "white !important",
        },
        "& .MuiTableSortLabel-root:hover": {
          color: "white",
        },
        "&.Mui-active": {
          color: "white",
        },
        "& .MuiTableSortLabel-icon": {
          color: "white !important",
          alignSelf: "center",
        },
      }}
    >
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="checkbox"
          padding="checkbox"
          sx={{ display: "none" }}
        >
          <MuiCheckbox
            disableRipple
            checked={checked}
            onChange={handleChange}
            size="medium"
            color="primary"
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="name"
          sortDirection={orderBy === "name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "name"}
            onClick={createSortHandler("name")}
            direction={orderBy === "name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.name")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="title"
          sortDirection={orderBy === "title" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "title"}
            onClick={createSortHandler("title")}
            direction={orderBy === "title" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.rank")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="facultyLine"
          sortDirection={orderBy === "facultyLine" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "facultyLine"}
            onClick={createSortHandler("facultyLine")}
            direction={orderBy === "facultyLine" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.line")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="department.name"
          sortDirection={orderBy === "department.name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "department.name"}
            onClick={createSortHandler("department.name")}
            direction={orderBy === "department.name" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.department")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="startDate"
          sortDirection={orderBy === "startDate" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "startDate"}
            onClick={createSortHandler("startDate")}
            direction={orderBy === "startDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptStart")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="endDate"
          sortDirection={orderBy === "endDate" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "endDate"}
            onClick={createSortHandler("endDate")}
            direction={orderBy === "endDate" ? order : "asc"}
          >
            {t("FLA.mainView.list.tableColumns.apptEnd")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="activeFlag"
          sortDirection={orderBy === "activeFlag" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "activeFlag"}
            onClick={createSortHandler("activeFlag")}
            direction={orderBy === "activeFlag" ? order : "asc"}
          >
            {t("FLA.filterPanel.options.activeInactive.label")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="actions">
          {t("FLA.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export const FacultyLeave = ({ filterData, navigate, location }) => {
  const [checked, setChecked] = useState([]);
  const { facultyLeaveFiltersPreference, pagination, setPagination } =
    useFacultyLeaveContext();
  const [facultyLeaveList, setFacultyLeaveList] = useState({});
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    getFacultyLeaveList(
      setFacultyLeaveList,
      setLoading,
      facultyLeaveFiltersPreference,
      pagination.page,
      rowsPerPage,
      orderBy,
      order,
      navigate,
      setAlert,
      clearAlert,
      location
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facultyLeaveFiltersPreference,
    pagination.page,
    rowsPerPage,
    orderBy,
    order,
    setAlert,
    clearAlert,
  ]);

  const handleCheckAll = (checkedAll) => {
    let checkAll = facultyLeaveList.values.map((data, index) => {
      return index;
    });
    setChecked(checkedAll ? checkAll : []);
  };

  const handleCheck = (event) => {
    let eventValue = parseInt(event.target.value);
    setChecked(
      checked.includes(eventValue)
        ? checked.filter((c) => c !== eventValue)
        : [...checked, eventValue]
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPagination({
      ...pagination,
      page: 0,
    });
  };
  return (
    <MuiGrid
      container
      spacing={2}
      wrap="nowrap"
      direction="column"
      sx={{ marginRight: "24px", marginTop: "6px" }}
    >
      <MuiGrid container item wrap="nowrap">
        <MuiTableContainer component={MuiPaper}>
          <MuiTable aria-label="customized table" stickyHeader>
            <EnhancedTableHead
              handleCheckAll={handleCheckAll}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <MuiTableBody>
              {loading && (
                <MuiTableRow>
                  <MuiTableCell colSpan="10" align="center">
                    <MuiCircularProgress />
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {!loading &&
                facultyLeaveList.values &&
                facultyLeaveList.values.map((row, index) => (
                  <MuiTableRow key={index}>
                    <MuiTableCell align="left" sx={{ display: "none" }}>
                      <MuiCheckbox
                        checked={checked.includes(index)}
                        disableRipple
                        size="medium"
                        onChange={handleCheck}
                        value={index}
                        color="primary"
                      />
                    </MuiTableCell>
                    <MuiTableCell align="left">
                      <MuiTooltip
                        title={
                          <>
                            <MuiTypography variant="body2">
                              {t("FLA.mainView.list.tableColumns.name")}
                              {": "}
                              {row.primaryName}
                            </MuiTypography>
                            <MuiTypography variant="body2">
                              {t(
                                "FLA.mainView.list.tooltips.name.text.universityID"
                              )}
                              {": "}
                              {row.universityId}
                            </MuiTypography>
                            <MuiTypography variant="body2">
                              {t(
                                "FLA.mainView.list.tooltips.name.text.sunetID"
                              )}
                              {": "}
                              {row.sunetId}
                            </MuiTypography>
                          </>
                        }
                        placement="bottom-start"
                      >
                        <Link
                          to={{
                            pathname:
                              "/faculty/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          {row.primaryName}
                        </Link>
                      </MuiTooltip>
                    </MuiTableCell>
                    <MuiTooltip title={row.title}>
                      <MuiTableCell align="left">{row.title}</MuiTableCell>
                    </MuiTooltip>
                    <MuiTooltip title={row.facultyLine}>
                      <MuiTableCell align="left">
                        {row.facultyLine}
                      </MuiTableCell>
                    </MuiTooltip>
                    <MuiTooltip title={row?.department?.name}>
                      <MuiTableCell align="left">
                        {row?.department?.name}
                      </MuiTableCell>
                    </MuiTooltip>
                    <MuiTableCell align="left">
                      {row.startDate &&
                        convertDateStrToFLADateStr(row.startDate)}
                    </MuiTableCell>
                    <MuiTableCell align="left">
                      {row.endDate && convertDateStrToFLADateStr(row.endDate)}
                    </MuiTableCell>
                    <MuiTableCell align="left">{row.activeFlag}</MuiTableCell>
                    <MuiTableCell align="left">
                      <MuiTooltip
                        title={t("FLA.mainView.list.tooltips.actions.text")}
                      >
                        <Link
                          to={{
                            pathname:
                              "/faculty/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                        >
                          <MuiListAltIcon fontSize="small" />
                        </Link>
                      </MuiTooltip>
                    </MuiTableCell>
                  </MuiTableRow>
                ))}
              {!loading && !facultyLeaveList.totalCount && (
                <MuiTableRow>
                  <MuiTableCell colSpan="10">
                    <MuiTypography
                      variant="h6"
                      color="inherit"
                      align="center"
                      paragraph={false}
                    >
                      {t("globals.list.messages.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          <MuiTablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={facultyLeaveList.totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};
