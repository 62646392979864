import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link } from "react-router-dom";
/** Material UI Imports **/
import {
  Grid as MuiGrid,
  Paper as MuiPaper,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Help as MuiHelpIcon,
  Warning as MuiWarningIcon,
} from "@mui/icons-material";
/** Import of expansion panels on Leave Accrued Taken page **/
import { LeaveAccruedTakenAccordion } from "./LeaveAccruedTakenAccordion/LeaveAccruedTakenAccordion";
import { CurrentAppointments } from "./CurrentAppointments";
import { AppointmentHistory } from "./AppointmentHistory";
import { AppointmentSegments } from "./AppointmentSegments";
import { AccrualSubPanels } from "./Accrual";
import { Leave } from "./Leave";
import { Audit } from "./Audit";
import { CreateEditViewLeaveActions } from "./LeaveActions/CreateEditViewLeaveActions";
import { CreateIndividualMilestone } from "./Milestone/CreateIndividualMilestone";
import { numberToPercentage } from "app/shared/utils";
/** Service Imports **/
import {
  getFacultyDetails,
  getAccruals,
} from "app/services/leaveAccrualTakenService";
import { Theme, useAlerts } from "common";

export const LeaveAccruedTaken = () => {
  //Get universityId from url params
  const { universityId } = useParams();
  const location = useLocation();
  /* State to be set in service */
  const [facultyDetails, setFacultyDetails] = useState({});
  const [accruals, setAccruals] = useState([]);

  /* State to indicate, reload data of Leave section */
  const [reloadLeave, setReloadLeave] = useState(false);
  /* State to indicate, reload data of Appointment Segments */
  const [reloadAppointmentSegments, setReloadAppointmentSegments] =
    useState(false);
  /* State to indicate, reload data of Audit */
  const [reloadAudit, setReloadAudit] = useState(false);
  /* State to indicate, reload data of Accrual */
  const [reloadAccrual, setReloadAccrual] = useState(false);

  const [loading, setLoading] = useState(false);
  const [accrualsLoading, setAccrualsLoading] = useState(false);
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  const [expandAllAccrual, setExpandAllAccrual] = useState(false);
  const [leaveNotesContent, setLeaveNotesContent] = useState("");
  const [isLeaveNoteEditable, setIsLeaveNoteEditable] = useState(false);

  // State for Leave Section Notes Dialog
  const [showLeaveSectionNotesDialog, setShowLeaveSectionNotesDialog] =
    useState(false);

  useEffect(() => {
    if (!Object.keys(facultyDetails).length) {
      getFacultyDetails(
        setFacultyDetails,
        setLoading,

        setAlert,
        clearAlert,
        universityId
      );
      getAccruals(
        setAccruals,
        setAccrualsLoading,
        setAlert,
        clearAlert,
        universityId
      );
    }
    if (reloadAccrual) {
      getAccruals(
        setAccruals,
        setAccrualsLoading,
        setAlert,
        clearAlert,
        universityId
      );
      setReloadAccrual(false);
    }
  }, [universityId, reloadAccrual, facultyDetails, clearAlert, setAlert]);
  const leaveAccruedTakenLabels = "LeaveAccruedTaken";
  const leaveAccruedTakenFacultyLabels = `${leaveAccruedTakenLabels}.facultyDetailsLabels`;
  const leaveAccruedTakenBreadcrumbs = `${leaveAccruedTakenLabels}.breadcrumb`;

  const hasServiceRequirementAlert = (facultyDetails) => {
    return (
      !!facultyDetails.fiveYearServiceRequirementMetDate &&
      !!facultyDetails.fiveYearServiceRequirementAlert
    );
  };

  const createServiceRequirementAlertTooltipText = (facultyDetails) => {
    let alerts = facultyDetails.fiveYearServiceRequirementAlert.split(",");

    return alerts
      .map((alert) =>
        t(`${leaveAccruedTakenFacultyLabels}.fiveYrServiceReqAlert.${alert}`)
      )
      .join(" & ");
  };

  const show3YearAverageFte = (facultyDetails) => {
    return (
      facultyDetails.hasPdlAppointment &&
      facultyDetails.fteThreeYearsAverage &&
      serviceRequirementIsMetAndNotInFuture(facultyDetails)
    );
  };

  const serviceRequirementIsMetAndNotInFuture = (facultyDetails) => {
    return (
      !!facultyDetails.fiveYearServiceRequirementMetDate &&
      !isFuture(facultyDetails.fiveYearServiceRequirementMetDate)
    );
  };

  const handleChangeExpandCollapseAllBtn = () => {
    setExpandAllAccrual(!expandAllAccrual);
  };

  const handleChangeLeaveSectionNotesDialog = () => {
    setShowLeaveSectionNotesDialog(!showLeaveSectionNotesDialog);
  };

  const isFuture = (dateStr) => {
    const today = new Date().toISOString().substr(0, 10);
    return dateStr > today;
  };

  /* GET Years in Rank for all faculty categories */
  const getYearsInRank = (facultyDetails) => {
    let getYearsInRank = null;
    if (!facultyDetails.yearsInRank.active) {
      getYearsInRank = t(
        `${leaveAccruedTakenFacultyLabels}.yearsInRankObject.INACTIVE`
      );
    } else if (!facultyDetails.yearsInRank.som) {
      getYearsInRank = t(
        `${leaveAccruedTakenFacultyLabels}.yearsInRankObject.NA`
      );
    } else {
      const fetcheDmonths = facultyDetails.yearsInRank.monthsInRank;
      const years = Math.floor(fetcheDmonths / 12);
      const months = fetcheDmonths % 12;
      getYearsInRank = `${t(
        `${leaveAccruedTakenFacultyLabels}.yearMonthObject.year`,
        {
          count: years,
        }
      )}, ${t(`${leaveAccruedTakenFacultyLabels}.yearMonthObject.month`, {
        count: months,
      })}`;
    }
    return getYearsInRank;
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      direction="column"
      sx={{ marginRight: "16px" }}
    >
      <MuiGrid
        container
        sx={{
          "& a": {
            color: "gray",
            cursor: "pointer",
            textDecoration: "none",
          },
        }}
      >
        <MuiBreadcrumbs separator=">" aria-label="breadcrumb">
          <Link color="inherit" to={`/faculty${location.search}`}>
            <MuiTypography color="textSecondary">
              {t(`${leaveAccruedTakenBreadcrumbs}.facultyListLabel`)}
            </MuiTypography>
          </Link>
          <MuiTypography color="textPrimary">
            {" "}
            {t(`${leaveAccruedTakenBreadcrumbs}.leaveAccruedLabel`)}
          </MuiTypography>
        </MuiBreadcrumbs>
      </MuiGrid>
      <MuiTypography color="textPrimary" variant="h1">
        {t(`${leaveAccruedTakenBreadcrumbs}.leaveAccruedLabel`)}
      </MuiTypography>
      {loading && (
        <MuiGrid container justifyContent="center">
          <MuiCircularProgress />
        </MuiGrid>
      )}
      {!loading && Object.keys(facultyDetails).length > 0 && (
        <>
          <MuiPaper
            elevation={3}
            sx={{ width: "100%", padding: "8px 16px 16px", marginTop: "8px" }}
          >
            <MuiTypography color="textPrimary" variant="h2">
              {facultyDetails.primaryName}
            </MuiTypography>
            <MuiGrid container item xs={11}>
              <MuiGrid item xs={5}>
                <MuiGrid container item direction="row" xs={10}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.prefName`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {facultyDetails.prefname}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item direction="row" xs={10}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.universityId`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {facultyDetails.universityId}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item direction="row" xs={10}>
                  <MuiGrid item xs={4}>
                    <MuiTypography color="textPrimary">
                      {t("globals.labels.sunetID")}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {facultyDetails.sunetId}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs={(facultyDetails.hasPdlAppointment && 6) || 4}>
                <MuiGrid container item direction="row" xs={11}>
                  <MuiGrid item xs={6}>
                    <MuiTypography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.academicApptDate`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary" fontWeight="600">
                      {facultyDetails.academicApptDate}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid container item direction="row" xs={11}>
                  <MuiGrid item xs={6}>
                    <MuiTypography color="textPrimary">
                      {t(`${leaveAccruedTakenFacultyLabels}.yearsInRank`)}:{" "}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography color="textPrimary">
                      {getYearsInRank(facultyDetails)}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                {show3YearAverageFte(facultyDetails) ? (
                  <MuiGrid container item direction="row" xs={11}>
                    <MuiGrid item xs={6}>
                      <MuiTypography color="textPrimary">
                        {t(`${leaveAccruedTakenFacultyLabels}.averageFTE`)}:{" "}
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item>
                      <MuiTypography color="textPrimary" fontWeight="600">
                        {numberToPercentage(
                          facultyDetails.fteThreeYearsAverage
                        )}
                        <MuiTooltip
                          title={t(
                            `${leaveAccruedTakenFacultyLabels}.averageFTEHelpText`
                          )}
                        >
                          <MuiHelpIcon
                            color="primary"
                            fontSize="small"
                            viewBox="0 0 22 22"
                            style={{ position: "relative", left: "4px" }}
                          />
                        </MuiTooltip>
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                ) : (
                  ""
                )}
                {facultyDetails.hasPdlAppointment &&
                  facultyDetails.fiveYearServiceRequirementMetDate && (
                    <MuiGrid container item direction="row" xs={11}>
                      <MuiGrid item xs={6}>
                        <MuiTypography color="textPrimary">
                          {t(
                            `${leaveAccruedTakenFacultyLabels}.fiveYrServiceReqMet`
                          )}{" "}
                          :{" "}
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography color="textPrimary" fontWeight="600">
                          {facultyDetails.fiveYearServiceRequirementMetDate}
                          {hasServiceRequirementAlert(facultyDetails) ? (
                            <MuiTooltip
                              title={createServiceRequirementAlertTooltipText(
                                facultyDetails
                              )}
                            >
                              <MuiWarningIcon
                                fontSize="small"
                                sx={{
                                  position: "relative",
                                  top: "4px",
                                  left: "4px",
                                  color: Theme.palette.warning.main,
                                  marginTop: "5px",
                                }}
                              />
                            </MuiTooltip>
                          ) : (
                            ""
                          )}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                  )}
              </MuiGrid>
            </MuiGrid>
          </MuiPaper>
          {/* Call to display Current Appointments panel*/}
          <LeaveAccruedTakenAccordion
            children={{
              title: t(
                `${leaveAccruedTakenLabels}.currentApptLabels.currentApptsTitle`
              ),
              summaryDetails: (
                <CurrentAppointments facultyDetails={facultyDetails} />
              ),
              defaultExpanded: true,
            }}
          />

          {/* Call to display Appointment History panel*/}
          {facultyDetails._links.viewFacultyAppointments && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.apptHistoryLabels.apptHistoryTitle`
                ),
                summaryDetails: (
                  <AppointmentHistory
                    universityId={universityId}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    showLeaveSectionNotesDialog={showLeaveSectionNotesDialog}
                    handleChangeLeaveSectionNotesDialog={
                      handleChangeLeaveSectionNotesDialog
                    }
                    setShowLeaveSectionNotesDialog={
                      setShowLeaveSectionNotesDialog
                    }
                    setLeaveNotesContent={setLeaveNotesContent}
                    setIsLeaveNoteEditable={setIsLeaveNoteEditable}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Appointment Segments panel*/}
          {facultyDetails._links.viewFacultySegments && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.appointmentSegmentsLabels.appointmentSegmentsTitle`
                ),
                contentButton: facultyDetails._links.addMilestone && (
                  <CreateIndividualMilestone
                    universityId={universityId}
                    setReloadAppointmentSegments={setReloadAppointmentSegments}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                  />
                ),
                summaryDetails: (
                  <AppointmentSegments
                    universityId={universityId}
                    reloadAppointmentSegments={reloadAppointmentSegments}
                    setReloadAppointmentSegments={setReloadAppointmentSegments}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    editMilestone={facultyDetails._links.editMilestone}
                    removeMilestone={facultyDetails._links.removeMilestone}
                    viewMilestone={facultyDetails._links.viewMilestone}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Accrual panel*/}
          {facultyDetails._links.viewFacultyAccruals && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.accrualLabels.accrualTitle`
                ),
                isSubOrdinateBtn: accruals.length !== 0,
                expandAllAccrual: expandAllAccrual,
                setExpandAllAccrual: setExpandAllAccrual,
                handleChangeSubOrdinateBtn: handleChangeExpandCollapseAllBtn,
                subOrdinateBtnText:
                  (!expandAllAccrual &&
                    t("globals.form.actionButtons.expandAll")) ||
                  (expandAllAccrual &&
                    t("globals.form.actionButtons.collapseAll")),
                isButtonEnable: true,
                summaryDetails:
                  (accrualsLoading && (
                    <MuiGrid container justifyContent="center">
                      <MuiCircularProgress />
                    </MuiGrid>
                  )) ||
                  accruals.map((data) => (
                    <LeaveAccruedTakenAccordion
                      children={{
                        title: data.year,
                        defaultExpanded: false,
                        expanded: expandAllAccrual,
                        summaryDetails:
                          data.pdlLeaves || data.sabbaticalLeaves ? (
                            <AccrualSubPanels
                              leavesData={data}
                              setReloadAccrual={setReloadAccrual}
                              setReloadAudit={setReloadAudit}
                              editAccrual={facultyDetails._links.editAccrual}
                            />
                          ) : (
                            <MuiGrid
                              container
                              spacing={5}
                              item
                              xs={3}
                              justifyContent="center"
                            >
                              <MuiGrid item>
                                {t(
                                  `${leaveAccruedTakenLabels}.accrualLabels.noAccrualMessage`
                                )}
                              </MuiGrid>
                            </MuiGrid>
                          ),
                        isSubExpansionPanel: true,
                      }}
                    />
                  )),
                defaultExpanded: false,
                hasSubSectionsExpansion: true,
              }}
            />
          )}
          {/* Call to display Leave panel*/}
          {facultyDetails._links.viewFacultyLeaves && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(
                  `${leaveAccruedTakenLabels}.leaveHistoryLabels.leaveTitle`
                ),
                contentButton: facultyDetails._links.addLeave && (
                  <CreateEditViewLeaveActions
                    universityId={universityId}
                    setReloadLeave={setReloadLeave}
                    setReloadAudit={setReloadAudit}
                    actionType="add"
                  />
                ),
                isSubOrdinateBtn: true,
                subOrdinateBtnText: t(
                  "globals.form.actionButtons.leaveSectionNotesBtn"
                ),
                handleChangeSubOrdinateBtn: handleChangeLeaveSectionNotesDialog,
                leaveNotes: leaveNotesContent,
                isButtonEnable: isLeaveNoteEditable,
                summaryDetails: (
                  <Leave
                    universityId={universityId}
                    reloadLeave={reloadLeave}
                    setReloadLeave={setReloadLeave}
                    setReloadAudit={setReloadAudit}
                    setReloadAccrual={setReloadAccrual}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                  />
                ),
                defaultExpanded: true,
              }}
            />
          )}
          {/* Call to display Audit panel*/}
          {facultyDetails._links.viewFacultyLeaves && (
            <LeaveAccruedTakenAccordion
              children={{
                title: t(`${leaveAccruedTakenLabels}.auditLabels.auditTitle`),
                summaryDetails: (
                  <Audit
                    universityId={universityId}
                    reloadAudit={reloadAudit}
                    setReloadAudit={setReloadAudit}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                  />
                ),
                defaultExpanded: false,
              }}
            />
          )}
        </>
      )}
    </MuiGrid>
  );
};
