import { useState, useCallback, Children, cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
/** Material UI Imports **/
import { Grid as MuiGrid, Typography as MuiTypography } from "@mui/material";
import { Theme } from "common";
import { FilterPanel } from "./FilterPanel";

export const Page = (props) => {
  const [open, setOpen] = useState(true);
  const [filterData, setFilterData] = useState({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  function handleDrawerToggle() {
    setOpen(!open);
  }
  const changeFilterData = useCallback((data) => {
    setFilterData(data);
  }, []);
  return (
    <MuiGrid container wrap="nowrap">
      {props.filter ? (
        <MuiGrid
          item
          sx={{
            borderRight: "1px solid",
            borderRightColor: Theme.palette.grey[400],
          }}
        >
          <FilterPanel
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            changeFilterData={changeFilterData}
          >
            {props.filter}
          </FilterPanel>
        </MuiGrid>
      ) : null}
      <MuiGrid
        container
        item
        wrap="nowrap"
        direction="column"
        sx={{ padding: "24px" }}
      >
        {props.title ? (
          <MuiGrid item>
            <MuiTypography variant="h1">{t(props.title)}</MuiTypography>
          </MuiGrid>
        ) : null}
        <MuiGrid item>
          {props.main &&
            Children.map(props.main, (child) => {
              return cloneElement(child, {
                filterData: filterData,
                navigate: navigate,
                location: location,
              });
            })}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
